/*
slick slider css 1.6.0, https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css
*/

.slick-list, .slick-slider, .slick-track {
    position: relative;
    display: block
}

.slick-loading .slick-slide, .slick-loading .slick-track {
    visibility: hidden
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    top: 0;
    left: 0
}

.slick-track:after, .slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

/*# sourceMappingURL=slick.min.css.map */

/*@font-face {*/
/*    font-family: Verlag;*/
/*    src: url("./fonts/Verlag-Black.woff2") format('woff2');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

html,
body,
main {
    scroll-behavior: smooth;
    font-family: var(--font-primary), sans-serif !important;
    overflow: hidden;
    margin: 0;
}

body,
html {
    overflow-y: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

*:focus {
    outline: 0;
}

/* progress bar colors */
.progress {
    align-items: center;
    height: 1.3rem;
    background: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    text-align: left;
    padding-left: 5px;
    padding-right: 25px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.38px;
    color: #000;
    height: 100%;
    border-radius: 8px;

}

.bg-danger {
    background-color: red !important;
}

.bg-color1 {
    background-color: #76db98 !important;
}

.bg-color2 {
    background-color: #b7ea83 !important;
}

.bg-color3 {
    background-color: #f6d757 !important;
}

.bg-color4 {
    background-color: #fbb851 !important;
}

.bg-color5 {
    background-color: #f17a54 !important;
}


.fbPageFixedDownloadButton {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding-top: 30px;
    bottom: 0;
    transition: .3s;
    background: #000;
    background: -moz-linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0) 100%);
}

.slick-slide > div {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.slick-track {
    height: 100% !important;
}

.slick-dots li.slick-active button::before {
    color: transparent;
}

.slick-dots.store-slick-thumb li.slick-active a {
    border-bottom: 2px solid #faf751;
}

.slick-dots.store-slick-thumb li.slick-active a img {
    filter: grayscale(0);
    opacity: 1;
}

.accordion-button {
    background: url(https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/collapsed-minus.svg) no-repeat 97% 50%;
    background-size: 3%;
    padding: 15px 20px 15px 20px;
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    text-transform: none;
    padding-right: 10%;
    padding-left: 3%;
    border: none;
}

.collapsed {
    background: url(https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/expended-plus.svg) no-repeat 97% 50%;
    background-size: 3%;
}

.accordion-body {
    padding: 1.25rem;
}

.accordion-button:focus {
    outline: none;
}

@media screen and (max-width: 1540px) {
    .h2 {
        font-size: 45px;
    }

    .p {
        font-size: 35px;
    }

    .p1 {
        font-size: 14px;
    }
}

.global-slick-dots {
    position: absolute;
    bottom: 15px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    padding: 0;
}

.global-slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.global-slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.global-slick-dots li button::before {
    height: 11px;
    width: 11px;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    content: '•';
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    opacity: 1 !important;
}

.global-slick-dots li.slick-active button:before {
    background-color: #fff !important;
    color: transparent;
}

.sliderDots_how_to_play li.slick-active button::before {
    color: #000;
    opacity: 0.75;
}

.dotsContainer li button:before {
    border: 1px solid #fff;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    color: transparent;
    opacity: 1 !important;
}

.dotsContainer li.slick-active button:before {
    background-color: #fff !important;
    color: transparent;
}

.sliderContainerWinZOWinners .slick-center .slideBorderContainer {
    transform: scale(125%);
    background: linear-gradient(to bottom, #f90 0%, #febf50 19%, #ffe5a4 38%, #f2b04d 69%, #ff7a00 100%);
    z-index: 99;
}

.sliderContainerWinZOWinners .slick-center .slideParentContainer {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
    z-index: 1;
}

.sliderContainer .slick-center .slideImage {
    height: 90%;
    width: 100%;
    border-radius: 30px;
}

.popularGamesSliderContainer .slick-center .slideImage {
    height: clamp(160px, 16.6vw, 320px);
    width: 100%;
    border-radius: 3vw;
}

.prizesOfContestSlider .slick-center .slide {
    transform: scale(1.1);
}

.prizesOfContestSlider .slick-dots .slick-active .ft-slick__dots--custom .main {
    background-color: #fff;
    opacity: 1;
}

.prizesOfContestSlider .slick-dots li .ft-slick__dots--custom .main {
    background-color: #d9d9d9;
    opacity: 30%;
}
.prizesOfContestSliderV2 .slick-dots .slick-active .ft-slick__dots--custom .main {
    background-color: #000;
    opacity: 1;
}

.prizesOfContestSliderV2 .slick-dots li .ft-slick__dots--custom .main {
    background-color: #d9d9d9;
    opacity: 30%;
}

.prizesOfContestSliderV2 .slick-dots li {
    width: 50px;
    margin-bottom: -10vw;
}

.prizesOfContestSlider .slick-dots li {
    width: 50px;
    margin-bottom: -10vw;
}

.makingTheLegacySlider .slick-dots .slick-active .ft-slick__dots--custom .main {
    background-color: #392564;
    opacity: 1;
}

.makingTheLegacySlider .slick-dots li .ft-slick__dots--custom .main {
    background-color: #d9d9d9;
    opacity: 30%;
}

.makingTheLegacySlider .slick-dots li {
    width: 50px;
}

.sliderContainer .slick-center .gameContent {
    display: block;
}

/* slick-theme.css  */

.slick-dots, .slick-next, .slick-prev {
    position: absolute;
    display: block;
    padding: 0
}

.slick-dots li button:before, .slick-next:before, .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-prev:hover:before {
    opacity: 1
}

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
    opacity: .25
}

.slick-next:before, .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff
}

.slick-prev {
    left: -25px
}

[dir=rtl] .slick-prev {
    right: -25px;
    left: auto
}

.slick-prev:before {
    content: '←'
}

.slick-next:before, [dir=rtl] .slick-prev:before {
    content: '→'
}

.slick-next {
    right: -25px
}

[dir=rtl] .slick-next {
    right: auto;
    left: -25px
}

[dir=rtl] .slick-next:before {
    content: '←'
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0
}

.slick-dots li button:focus, .slick-dots li button:hover {
    outline: 0
}

.slick-dots li button:focus:before, .slick-dots li button:hover:before {
    opacity: 1
}

.slick-dots li button:before {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000
}

.rating-slick-dots li {
    width: auto;
    height: auto;
}

.rating-slick-dots li.slick-active > div {
    background: #35255a !important
}

/*
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000
}
*/

.aboutGdcSlider .slick-center .slider-container {
    transform: scale(1.2);
}

.aboutGdcSlider .slick-center .mobile-slider-container {
    transform: scale(1.2);
}

.prizesOfContestSliderV2 .slick-center {
    transform: scale(1.2);
}
@media screen and (max-width: 767px) {
    .accordion-button {
        background-size: 3%;
        padding: 5px 30px 5px 10px;
        font-size: 15px;
    }

    .collapsed {
        background-size: 3%;
        padding: 5px 30px 5px 10px;
    }

    .progress {
        height: 0.8rem;
        border-radius: 3.2px;
    }

    .progress-bar {
        border-radius: 3.2px;
    }
}

@media screen and (max-width: 576px) {
    body:has(.floatingDownloadButtonContainer) {
        padding-bottom: 100px;
    }

    .progress-bar {
        padding-right: 20px;
        font-size: 10px;
    }

    .accordion-button {
        font-size: 13px;
    }

    .rating-slick-dots li {
        margin: 0 2px;
    }

    #id_howToInstall .rating-slick-dots li.slick-active > div {
        background: #ffffff !important
    }

    .popularGamesSliderContainer {
        display: none;
    }
}


.innerSlider > div {
    margin-left: 30px;
    margin-right: 30px;
}

.winnerContainer > div:nth-child(2) {
    position: absolute !important;
    right: 7% !important;
    top: 50px !important;
}

div > .uspImg {
    height: auto;
    width: auto;
    object-fit: contain;
}

div > .memberImg {
    width: 240px;
    height: 126px;
    object-fit: contain;
}

div > .proudSponsorImg {
    width: 240px;
    height: 126px;
    object-fit: contain;
}

div > .kkrImg {
    width: 200px;
    height: 200px;
}

div > .storeImg {
    height: 300px !important;
}

div > .superstarImage {
    border-radius: 50%;
}

div > .fbPageWithdrawalImage {
    width: auto;
    height: 52px;
    margin: auto;
}

.faqCard {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    background: #f1f1f1;
    border: none;
    margin-bottom: 20px;
    border-radius: 6px;

}

.faqCardHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    padding: 0;
    border-bottom: none;
}

.footerLinks > a {
    color: white !important;
}

.superstarSliderDots {
    bottom: 30px;
}

.superstarSliderDots li button::before {
    border: 1px solid #fff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    color: transparent;
    opacity: 1 !important;
}

.storePartnerBackgroundImg {
    background-image: url(https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/store-copy.png), url(https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/store-copy.png);
    background-size: 24%;
    background-position: left center, right center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, .5)
}

.winzoStoreBannerDots {
    bottom: 100px;
    height: auto;
    width: 100%;
    min-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    bottom: 80px;
}

.winzoStoreBannerDots::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
    background-color: #f5f5f5
}

.winzoStoreBannerDots::-webkit-scrollbar {
    background-color: #f5f5f5;
    height: 4px
}

.winzoStoreBannerDots::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5)
}

.winzoStoreBannerDots > li {
    width: auto;
    padding: 0 1%;
    height: auto
}

.winzoStoreBannerDots li a {
    border-bottom: 2px solid transparent;
    display: block;
    overflow: auto;
    padding-bottom: 18px;
}

.winzoStoreBannerDots li a img {
    filter: grayscale(100%);
    opacity: .5;
    height: 60px;
    width: auto;
    display: block;
}

.winzoStoreSliderDots {
    bottom: -24px;
    position: static;
}

.winzoStoreSliderDots li button::before {
    border: 1px solid #fff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    color: transparent;
    opacity: 1 !important;
}

.winzoStoreSliderItem {
    display: flex;
}

.winzoStoreSliderItem > div > div {
    background: linear-gradient(180deg, #fcf221 0, #f69922 100%);
    padding: 2px 5px;
}

.worldwarSlickDots li button::before {
    width: 15px;
    height: 15px;
}

.worldwarSlickDots li {
    width: 12px;
}

.worldwarSlickDots li button::before {
    border: solid 0.5px #fff;
    border-radius: 50%;
    color: transparent;
    background-color: rgba(51, 51, 51, 0.7);
    opacity: 1 !important;
}

.worldwarSlickDots li.slick-active button::before {
    background-image: linear-gradient(to top, #62258a, #b3097f);
}

.aboutGdcSlickDots li button::before {
    width: 100%;
    height: 100%;
}

.aboutGdcSlickDots li {
    width: clamp(14px, 5.2vw, 100px);
    height: clamp(2px, 0.5vw, 10px);
}

.aboutGdcSlickDots li button::before {
    border-radius: 20px;
    color: transparent;
    background-color: #ffffff;
    opacity: .3;
}

.aboutGdcSlickDots li.slick-active button::before {
    opacity: 1;
}

.makingLegacySlickDots li button::before {
    width: 100%;
    height: 100%;
}

.makingLegacySlickDots li {
    width: clamp(14px, 5.2vw, 70px);
    height: clamp(4px, 0.5vw, 10px);
}

.makingLegacySlickDots li button::before {
    border-radius: 20px;
    color: transparent;
    background-color: #330066;
    opacity: .1;
}

.makingLegacySlickDots li.slick-active button::before {
    opacity: 1;
}


div > .gameTricksImage {
    border-radius: 12px;
}

div > a > .refundPolicyImage {
    width: 180px;
    height: auto;
}

.fbPageMainContainer {
    background: #62258a;
    background-image: -moz-linear-gradient(270deg, #62258a 0, #b3097f 100%);
    background-image: -webkit-linear-gradient(270deg, #62258a 0, #b3097f 100%);
    background-image: linear-gradient(270deg, #62258a 0, #b3097f 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 72px;
    padding-bottom: 40px;
    display: flex;
}

.howToInstallImageV2 {
    border-radius: 10px;
}

a, a:hover {
    text-decoration: none;
}

.winzoWinnerImage {
    border-radius: clamp(6px, 0.6vw, 12px);
}

@media screen and (max-width: 1540px) {
    div > .proudSponsorImg {
        height: 250px !important;
    }

    div > .kkrImg {
        height: 250px !important;
    }

    div > .storeImg {
        height: 300px !important;
    }
}

@media screen and (max-width: 1024px) {

    .winnerContainer > div:nth-child(2) {
        width: 60px;
        height: 45px;
    }

    div > .uspImg {
        height: 80px;
        width: auto;
    }

    div > .memberImg {
        height: 80px;
        width: auto;
    }

    div > .proudSponsorImg {
        height: 80px;
        width: auto;
    }

    div > .kkrImg {
        height: 80px;
        width: auto;
    }

    div > .storeImg {
        height: 280px !important;
    }

    .sectionContainer {
        display: none;
    }

    .sectionCol {
        font-size: 12px;
    }

    .superstarSliderDots {
        bottom: 30px;
    }

    .winzoStoreBannerDots li a img {
        height: 50px;
    }

    .winzoStoreSliderDots {
        bottom: -11px;
    }

    div > a > .refundPolicyImage {
        width: 130px;
    }

    .fbPageMainContainer {
        min-height: 500px;
    }
}

@media screen and (max-width: 767px) {
    .sliderContainerWinZOWinners .slick-center .slideBorderContainer {
        transform: scale(100%, 100%);
        background: linear-gradient(to bottom, #f90 0%, #febf50 19%, #ffe5a4 38%, #f2b04d 69%, #ff7a00 100%);
    }

    .sliderContainerWinZOWinners .slick-center .slideParentContainer {
        z-index: 1;
        width: 100%;
    }

    .worldwarSlickDots li button::before {
        width: 10px;
        height: 10px;
    }

    .winnerContainer > div:nth-child(2) {
        width: 50px;
        height: 30px;
    }

    div > .uspImg {
        height: 70px;
        width: auto;
    }

    div > .memberImg {
        height: 70px;
        width: auto;
    }

    div > .proudSponsorImg {
        height: 70px;
        width: auto;
    }

    div > .kkrImg {
        height: 100px;
        width: 250px;
    }

    div > .storeImg {
        height: 200px !important;
    }

    .faqCard {
        margin-bottom: 10px;
    }

    .superSliderContainer > div > div > div {
        width: 800px !important;
    }

    .superstarSliderDots {
        bottom: 15px;
    }

    .winzoStoreBannerDots {
        bottom: 60px;
    }

    .winzoStoreBannerDots li a {
        padding-bottom: 10px;
    }

    .winzoStoreBannerDots li a img {
        height: 35px;
    }

    .winzoStoreSliderDots {
        bottom: -16px;
    }

    .winzoStoreSliderDots li button::before {
        height: 11px;
        width: 11px;
    }

    div > .fbPageWithdrawalImage {
        width: auto;
        height: 17px;
        margin: auto;
    }

    .fbPageMainContainer {
        padding-bottom: 0;
        margin-top: 42px;
        display: flex;
        align-items: stretch;
    }
}

@media screen and (max-width: 576px) {
    .prizesOfContestSlider .slick-dots li {
        width: 15px;
        margin-bottom: -80px;
    }
    .prizesOfContestSliderV2 .slick-dots li {
        width: 15px;
        margin-bottom: -80px;
    }

    .makingTheLegacySlider .slick-dots li {
        width: 15px;
    }

    .winnerContainer > div:nth-child(2) {
        display: none !important;
    }


    div > .memberImg {
        max-height: 70px !important;
        min-height: 70px !important;
        max-width: 90px !important;
        min-width: 90px !important;
    }

    div > .proudSponsorImg {
        max-height: 70px !important;
        min-height: 70px !important;
        max-width: 90px !important;
        min-width: 90px !important;
    }

    div > .kkrImg {
        max-height: 104px !important;
        min-height: 104px !important;
        max-width: 280px !important;
        min-width: 280px !important;
    }

    div > .storeImg {
        height: 150px !important;
    }

    .superstarSliderDots {
        bottom: 10px;
    }

    .winzoStoreBannerDots {
        bottom: 40px;
    }

    .winzoStoreBannerDots li a img {
        height: 25px;
    }

    .winzoStoreSliderDots {
        bottom: -12px;
    }
}

.customBreadcrumb {
    background-color: rgba(255, 255, 255, 0);
    font-size: .8em;
    padding: 0 0 0.75rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
}